<template>
  <div>
    <gmap-map
      ref="gmap"
      :center="{ lat: markers[0].latitude, lng: markers[0].longitude }"
      :zoom="10"
      :style="styleData"
    >
    <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="getAdjustedPosition(m, index)"
          :icon="m.isHovered ? blueIcon : redIcon"
          @mouseover="m.isHovered = true; toggleInfoWindow(m, index)"
          @mouseout="m.isHovered = false;"
        ></gmap-marker>

      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
        
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </gmap-map>
  </div>
</template>
<script>
export default {
  name: "GoogleMap",
  props: {
    markers: {
      type: Array,
      default: [],
    },
    styleData: {
      type: String,
      default: "width:100%;  height: 100vh;",
    },
  },
  data() {
    return {
      //a default center for the map
      // baseAPI: process.env.VUE_APP_BASE_API + process.env.VUE_APP_IMAGE_PATH,
      center: { lat: 52.51195, lng: 6.089625 },
      map: null,
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      redIcon: null,
      blueIcon: null,
    };
  },
  mounted() {
    //set bounds of the map
    // this.markers.pop();
    console.log("this.markers =============>>>>>>>>>>", this.markers);
      this.redIcon = `${this.$store.state.common.publicPath}img/location-dot-solid_2__1_.png`;
    this.blueIcon = `${this.$store.state.common.publicPath}img/location-dot-solid_1__1_.png`;
    
    this.markers.forEach((marker) => {
      this.$set(marker, 'isHovered', false);
    });
    window.addEventListener('infoWindowClick', (event) => {
      this.getDetail(event.detail.id);
    });
    this.$refs.gmap.$mapPromise
      .then((map) => {
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds();
        for (let m of this.markers) {
          bounds.extend(m.latitude, m.longitude);
        }
        map.fitBounds(bounds);
      })
      .catch((error) => {});
  },
  methods: {
    getAdjustedPosition(marker, index) {
      // console.log("index", index);
      const offset = 0.01; // Adjust this value for finer control
      // const row = Math.floor(index / 10); // Number of columns in your grid
      const col = index % 10;
      const lat = marker.latitude + col * offset;
      const lng = marker.longitude + col * offset;
      // console.log("lat", lat);
      // console.log("lng", lng);
      return { lat, lng };
    },
    toggleInfoWindow: function (marker, idx) {
      // console.log("marker",marker)
      // console.log("idx",idx)
      let position = this.getAdjustedPosition(marker, idx);
      this.infoWindowPos = position;
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    getDetail(id) {
      id = btoa(id);
      this.$router.push("/detail/" + id);
    },

    getInfoWindowContent: function (marker) {
      // console.log(marker, "====1111111");

      return `<div class="card" onclick="window.dispatchEvent(new CustomEvent('infoWindowClick', { detail: { id: '${marker.id}' } }))">
                <div class="card-image">
                  <img style="height: 150px; width: 100%;" 
                      src="${process.env.VUE_APP_BASE_API}${marker?.thumbnail_image?.includes("new_images") == true
                              ? ""
                              : process.env.VUE_APP_IMAGE_PATH}${marker.thumbnail_image}">
                </div>
                <div class="card-content">
                  <div class="">
                    <h3 style="margin: 0;">
                      ${
                        marker.title.split("").length > 35
                          ? `${marker.title.substring(0, 35)}...`
                          : marker.title
                      }
                    </h3> 
                    <p style="margin: 0;">
                      ${
                        marker.location.split("").length > 35
                          ? `${marker.location.substring(0, 35)}...`
                          : marker.location
                      }
                    </p>
                  </div>
              </div>`;
    },
  },
};
</script>

<style>
.gm-style-iw-d {
  height: auto !important;
}
.gm-style .gm-style-iw-c {
  padding: 0px !important;
}
.gm-style .gm-style-iw-d {
  overflow: visible !important;
  max-height: fit-content !important;
}
.card-content {
  padding: 10px;
}
</style>
